<template lang="">
    <div>
        <div v-if="dataGuestsChart.labels" style="width:60%">
            <GuestsChart
            :data="dataGuestsChart"
            :options="optionsGuestsChart"
        />
        </div>

    </div>
</template>
<script>
import { report } from '@/api/report'
import dayjs from 'dayjs'
import GuestsChart from '@/views/modules/welcome/guestsChart'
export default {
    components: {
        GuestsChart
    },
    data () {
      return {
        keyWord: {
                StartDate: this.getUTC('start', 'day'),
                EndDate: this.getUTC('end', 'day'),
                BookingCode: null,
                ClientId: null,
                SourceId: null,
                CourseId: null,
                CardCode: null,
                IsSummary: true
            },
          dataGuestsChart: {
              labels: null,
              datasets: [{
                label: this.$t('golf_wellcome_unit_guests'),
                backgroundColor: ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850', '#C3C750', '#C78750'],
                data:  null
              }]
            },
          listSummary: [],
            optionsGuestsChart: {
              title: {
                display: true,
                text: null
              },
              responsive: true,
              maintainAspectRatio: false
            },
            timeZoneOffSet: localStorage.getItem('timeZoneOffset'),
            DateGuestsChart: [],
            ValueGuestsChart: [],
            responsegetListBooking_RF01: null,
      }
    },
    created() {
      try {
        this.getListBooking_RF01()
    } catch (error) {
      // Handle the exception
      console.error('An error occurred:', error)
    }
    },
    methods: {
       async getListBooking_RF01() {
            this.DateGuestsChart = [dayjs(this.keyWord.StartDate).add(-6, 'day').format("DD/MM/YYYY"),dayjs(this.keyWord.StartDate).add(-5, 'day').format("DD/MM/YYYY"),dayjs(this.keyWord.StartDate).add(-4, 'day').format("DD/MM/YYYY"),dayjs(this.keyWord.StartDate).add(-3, 'day').format("DD/MM/YYYY"),dayjs(this.keyWord.StartDate).add(-2, 'day').format("DD/MM/YYYY"),dayjs(this.keyWord.StartDate).add(-1, 'day').format("DD/MM/YYYY"),dayjs(this.keyWord.StartDate).format("DD/MM/YYYY")]
            this.keyWord.StartDate =  dayjs(this.keyWord.StartDate).add(-6, 'day').add(-this.timeZoneOffSet,'minute').format("YYYY-MM-DDTHH:mm:ss")
            const body = this.keyWord
            await report.API_RF01(body).then((response) => {
              if(response.Status == 200 && response.Data != null && response.Data.Summary != null) {
               this.listSummary = [...response.Data.Summary]
              }
            })
            this.DateGuestsChart.forEach(x => {
              var sum = 0
                  if(this.listSummary.find(y => this.convertUTC(y.Date, "DD/MM/YYYY") == x)) {
                    const data = this.listSummary.find(y => this.convertUTC(y.Date, "DD/MM/YYYY") == x).Status.filter(z => z.BookingStatus !== "CANCELLED" & z.BookingStatus !== "NO_SHOW")
                    data.forEach(item => {
                      sum += item.Count
                    })
                  }
                  this.ValueGuestsChart.push(sum)
                })
                this.dataGuestsChart.datasets[0].data = this.ValueGuestsChart
                this.dataGuestsChart.labels = this.DateGuestsChart
                this.optionsGuestsChart.title.text = `${this.$t('golf_wellcome_guests_chart')} (${this.DateGuestsChart[0]} - ${this.DateGuestsChart[6]})`
        },
    },
}
</script>
<style lang="scss">
</style>